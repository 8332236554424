



















import Vue from "vue";

export default Vue.extend({
  name: "yes-not-selection",
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
    labelYes: {
      type: String,
      default: "Yes",
    },
    labelNot: {
      type: String,
      default: "Not",
    },
    description: String,
  },
  data() {
    return {
      selection: false,
    };
  },
  mounted() {
    this.selection = this.value || false;
  },
  methods: {
    onChange(value: boolean) {
      this.$emit("input", value);
    },
    toggleSelection() {
      this.selection = !this.selection;
      this.$emit("input", this.selection);
    },
  },
});
